
:root {
  --darktheme_color1: rgba(37, 38, 37, 1);
  --background_color1: rgb(64,63,59,1);
}


html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color:var(--darktheme_color1);
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

root {
  margin: 0;
  padding: 0;
  width:100%;
  height:100%;
}

